.footer-wrapper {
  background-color: transparent;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dark.footer-wrapper .header {
  color: #fff;
}

.dark.mobile-footer-wrapper .header {
  color: #fff;
}

.dark .mobile-footer-wrapper-2 {
  color: #fff;
}

.dark .mobile-footer-wrapper-3 {
  color: #fff;
}

.footer-wrapper .body {
  font-size: 14px;
  color: #9ca3af;
  cursor: pointer;
  line-height: 2.86;
}
.footer-wrapper .body:hover {
  color: #19c6bc;
}

.mobile-footer-wrapper {
  padding-top: 2.5rem;
}

.mobile-footer-wrapper .header {
  font-size: 14px;
  font-weight: bold;
  line-height: 2.86;
}

.mobile-footer-wrapper .link {
  font-size: 14px;
  cursor: pointer;
  line-height: 2.86;
  color: #9ca3af;
}

.mobile-footer-wrapper .link:hover {
  color: #19c6bc;
}

.mobile-footer-wrapper-2 .body {
  font-size: 14px;
  font-weight: bold;
  margin-left: 0.35rem;
}

.mobile-footer-wrapper-2 .telegram {
  margin-left: 1rem;
}

.mobile-footer-wrapper-2 .telegram img,
.mobile-footer-wrapper-2 .twitter img {
    height: 1.25rem;
    width: 1.25rem;
}

.mobile-footer-wrapper-3 .powered {
  font-size: 14px;
  font-weight: bold;
  line-height: 3.14;
  margin-top: 3rem;
}

.mobile-footer-wrapper-3 .chain-link,
.mobile-footer-wrapper-3 .bsc {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.mobile-footer-wrapper-3 .text {
    margin-left: 0.35rem;
}

.mobile-footer-wrapper-3 .chain-link img {
  height: 2rem;
  width: 2rem;
}

.mobile-footer-wrapper-3 .bsc img {
  height: 2rem;
  width: 2rem;
}
