@media only screen and (max-width: 767px) {
  #launcher {
    position: fixed !important;
    bottom: 0% !important;
    right: 0rem;
  }
}

@media(min-width: 767px) {
  #launcher {
    position: fixed !important;
    bottom: 4% !important;
    right: 0rem;
  }
}